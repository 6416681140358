<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryForm"
      size="small"
      :inline="true"
      label-width="98px"
    >
      <el-form-item label="课程名称" prop="title">
        <el-input
          v-model="queryParams.title"
          placeholder="请输入课程名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="报名时间" prop="hasReferee">
        <el-date-picker
          v-model="dateRange"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="姓名" prop="nickName">
        <el-input
          v-model="queryParams.nickName"
          placeholder="请输入姓名"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="handleQuery"
          >搜索</el-button
        >
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
          >重置</el-button
        >
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <div style="color: #7f7f7f">
          合计:
          <span style="font-weight: 700">{{ registrationList.length }}</span
          >人
        </div>
      </el-col>
      <el-col :span="1.5" style="float: right">
        <el-button
          type="primary"
          plain
          size="mini"
          @click="exportList"
          v-hasPermi="['mall:onlineSign:export']"
          >导出名单</el-button
        >
      </el-col>
    </el-row>

    <el-table :data="registrationList" v-loading="loading" border :key="type">
      <el-table-column
        :label="type == 3 ? '研学名称' : '课程名称'"
        align="center"
        prop="title"
      />
      <el-table-column label="姓名" align="center" prop="nickName" />
      <el-table-column label="下单手机号码" align="center" prop="tel" />
      <el-table-column
        label="购买个数"
        align="center"
        prop="quantity"
        v-if="type != 1"
      />
      <el-table-column
        label="参会人姓名"
        align="center"
        prop="names"
        v-if="type == 2"
      />
      <el-table-column
        label="手机号码"
        align="center"
        prop="tels"
        v-if="type == 2"
      />
      <el-table-column label="单价" align="center" prop="unitPrice" />
      <el-table-column
        label="订单总价"
        align="center"
        prop="totalAmount"
        v-if="type != 1"
      />
      <el-table-column label="优惠" align="center" prop="discount" />
      <el-table-column label="实付金额" align="center" prop="payAmount" />
      <el-table-column label="报名时间" align="center" prop="createTime">
        <template slot-scope="scope">
          <div>
            {{ parseTime(scope.row.createTime) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        prop="createTime"
        v-if="type == 2"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            v-if="scope.row.tel"
            @click="handleOpenRegisterInfo(scope.row)"
            >修改报名信息</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <el-dialog
      title="修改报名信息"
      center
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose"
    >
      <el-form
        :model="{ registerList }"
        inline-message
        :rules="rules"
        ref="ruleForm"
        label-width="0"
      >
        <el-table :data="registerList" border>
          <el-table-column label="序号" align="center" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <el-form-item
                label=""
                :prop="`registerList.${scope.$index}.names`"
                :rules="{
                  required: true,
                  validator: validateNamePass,
                  trigger: 'blur',
                }"
              >
                <el-input v-model="scope.row.names"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="手机号码" align="center">
            <template slot-scope="scope">
              <el-form-item
                label=""
                :prop="`registerList.${scope.$index}.tels`"
                :rules="{
                  required: true,
                  validator: validatePhonePass,
                  trigger: 'blur',
                }"
              >
                <el-input v-model="scope.row.tels"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleEditRegisterInfo"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  onlineNameList,
  onlineExport,
  lineNameList,
  researchList,
  offlineSignEdit,
} from "@/api/virtualshoppingmall/registrationList";
// import { offlineSignEdit } from "@/api/virtualshoppingmall/registrationList";

export default {
  name: "Registration",
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        title: null,
        dateBegin: null,
        dateEnd: null,
      },
      loading: false,
      dateRange: [],
      total: 0,
      registrationList: [],
      type: 1, //从哪个页面进来(1 线上课 2 线下课 3 研学)
      dialogVisible: false,
      registerList: [],
      rules: {},
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.getList();
    this.setActiveMenu();
  },
  mounted() {},
  activated() {
    let newType = this.$route.query.type; //判断type是否改变

    if (newType != this.type) {
      this.type = this.$route.query.type;
      this.getList();
    }
  },
  watch: {
    $route() {
      this.setActiveMenu();
    },
  },
  methods: {
    getList() {
      if (this.dateRange && this.dateRange.length > 1) {
        this.queryParams.dateBegin = this.dateRange[0];
        this.queryParams.dateEnd = this.dateRange[1];
      } else {
        this.queryParams.dateBegin = null;
        this.queryParams.dateEnd = null;
      }
      this.loading = true;
      if (this.type == 1) {
        onlineNameList(this.queryParams).then((res) => {
          this.registrationList = res.rows;
          this.total = res.total;
          this.loading = false;
        });
      } else if (this.type == 2) {
        lineNameList(this.queryParams).then((res) => {
          // this.registrationList = res.rows;
          this.registrationList = res.rows;
          this.total = res.total;
          this.loading = false;
        });
      } else {
        researchList(this.queryParams).then((res) => {
          this.registrationList = res.rows;
          this.total = res.total;
          this.loading = false;
        });
      }
    },
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    // 打开修改报名信息弹窗
    handleOpenRegisterInfo(item) {
      this.currentData = item;
      this.registerList = this.registrationList.filter(
        (v) => v.orderId == item.orderId
      );
      console.log(
        "this.registerList :>> ",
        this.registerList,
        this.currentData
      );
      this.dialogVisible = true;
    },
    handleEditRegisterInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          console.log("this.registerList :>> ", this.registerList);
          let data = {
            orderId: this.registerList[0].orderId,
            persons: this.registerList.map((v) => {
              return {
                name: v.names,
                tel: v.tels,
              };
            }),
          };
          offlineSignEdit(data).then((res) => {
            console.log(res);
            this.dialogVisible = false;
            this.resetForm("ruleForm");
            this.getList();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 关闭 弹窗
    handleClose() {
      // this.resetForm("ruleForm");
      this.dialogVisible = false;
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.dateRange = [];
      this.handleQuery();
    },
    exportList() {
      if (this.type == 1) {
        this.downloadName(
          "mall/virtualOrder/onlineSignExport",
          {
            ...this.queryParams,
          },
          `线上课报名名单_${new Date().getTime()}.xlsx`
        );
      } else if (this.type == 2) {
        this.downloadName(
          "mall/virtualOrder/offlineSignExport",
          {
            ...this.queryParams,
          },
          `线下课报名名单_${new Date().getTime()}.xlsx`
        );
      } else {
        this.downloadName(
          "mall/virtualOrder/yanxueSignExport",
          {
            ...this.queryParams,
          },
          `研学课报名名单_${new Date().getTime()}.xlsx`
        );
      }
    },
    setActiveMenu() {
      let activeIndex = this.$router.options.routes.findIndex(
        (item) => item.path == "/virtualShoppingMall"
      );
      let index = this.$route.query.type;
      let pathName =
        index == 1
          ? "/virtualShoppingMall/online"
          : index == 2
          ? "/virtualShoppingMall/lineclass"
          : "/virtualShoppingMall/research";
      this.$set(
        this.$router.options.routes[activeIndex].children[0].meta,
        "activeMenu",
        pathName
      );
    },
    validatePhonePass(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else {
        if (
          !/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[0-9])\d{8}$/.test(
            value
          )
        ) {
          callback(new Error("请输入正确的手机号码"));
        }
        callback();
      }
    },
    validateNamePass(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入姓名"));
      } else {
        if (value.length < 2 || value.length > 20) {
          callback(new Error("长度在 2 到 20 个字符"));
        }
        callback();
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
