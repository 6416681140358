<template>
  <div class="app-container edit-course-container">
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
      <el-form-item label="课程名称" prop="lessonName">
        <el-input v-model="form.lessonName" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="单价" prop="unitPrice">
        <el-input
          placeholder="请输入内容"
          v-model="form.unitPrice"
          style="width: 300px"
          type="number"
        >
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="封面图" prop="coverImage"
        >视频数量
        <ImageUpload
          :limit="1"
          :isShowTip="false"
          @input="coverInput"
          :value="form.coverImage"
        />
      </el-form-item>
      <el-form-item label="轮播图" prop="bannerImages">
        <ImageUpload
          :limit="5"
          :isShowTip="false"
          @input="bannerInput"
          :value="form.bannerImages"
        />
      </el-form-item>
      <el-form-item label="编辑详情" prop="content">
        <editor v-model="form.content" :min-height="180" />
      </el-form-item>
      <el-form-item label="是否上架" prop="release">
        <el-switch v-model="form.release"></el-switch>
      </el-form-item>

      <el-form-item label="是否首页展示" v-if="lessonType != '线上券'">
        <el-radio-group v-model="form.homeShow">
          <el-radio label="1">是</el-radio>
          <el-radio label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="视频数量">
        <span>{{ form.videos && form.videos.length }}</span>
        <el-form :model="{ videos }" ref="videosForm" inline-message>
          <el-table :data="videos">
            <el-table-column type="index" label="排序" width="50">
            </el-table-column>
            <el-table-column prop="title" label="视频名称" width="320">
              <template slot-scope="scope">
                <el-form-item
                  :prop="`videos.${scope.$index}.title`"
                  :rules="{
                    required: true,
                    message: '请输入课视频名称',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    placeholder="请输入视频名称"
                    v-model="scope.row.title"
                    style="width: 280px"
                    type="text"
                  >
                  </el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="coverurl" label="视频封面" width="300">
              <template slot-scope="scope">
                <el-image
                  :src="scope.row.coverurl"
                  mode="widthFix"
                  style="width: 160px"
                />
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  @click="handlePreview(scope.row)"
                  type="text"
                  size="small"
                  >预览</el-button
                >
                <el-button
                  @click="handleDelete(scope.row)"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
      @close="
        () => {
          openVideoPlay = false;
          currentVideoUrl = '';
        }
      "
      title="视频预览"
      :visible.sync="openVideoPlay"
      width="800px"
      append-to-body
    >
      <video
        style="width: 720px"
        :src="currentVideoUrl"
        autoplay
        controls
      ></video>
    </el-dialog>
  </div>
</template>

<script>
import ImageUpload from "@/components/ImageUpload/newUpload";
import {
  getLessonOnlineDetail,
  updateLessonOnline,
  getOnlineDetail,
} from "@/api/virtualshoppingmall/onlineclass";
export default {
  components: { ImageUpload },
  data() {
    return {
      openVideoPlay: false,
      currentVideoUrl: "",

      rules: {
        lessonName: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
        ],
        unitPrice: [
          { required: true, message: "请输入单价", trigger: "change" },
        ],
        coverImage: [
          { required: true, message: "请上传封面图", trigger: "change" },
        ],
        bannerImages: [
          { required: true, message: "请上传轮播图", trigger: "change" },
        ],
      },
      form: {
        lessonName: "",
        unitPrice: "",
        coverImage: "",
        bannerImages: [],
        detailImages: [],
        release: "",
        homeShow: "",
        resource: "",
        desc: "",
        ImageUpload: [],
      },
      lessonId: "",
      tableData: [],
      videoList: [],
      lessonType: "",
      videos: [],
    };
  },
  created() {
    this.lessonId = this.$route.query.lessonId;
    this.lessonType = this.$route.query.lessonType;
    if (this.lessonId) {
      this.getLessonDetail();
      // this.getOnlineDetailFn();
    }
  },
  methods: {
    getOnlineDetailFn() {
      getOnlineDetail({ id: this.lessonId }).then((res) => {
        console.log(res);
        let videoList = [];
        res.data.child_list.forEach((item) => {
          item.record.forEach((v) => {
            videoList.push(v);
          });
        });
        console.log("videoList", videoList);

        this.videoList = videoList;
      });
    },
    handleDelete(row) {
      this.$confirm("确定删除该视频？", "系统确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.form.videos = this.form.videos.filter(
            (item) => item.id != row.id
          );
          this.videos = this.videos.filter((item) => item.id != row.id);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handlePreview(row) {
      getOnlineDetail({
        id: this.form.sourceId,
      }).then((res) => {
        console.log(res);
        let videoList = [];
        res.data.child_list.forEach((item) => {
          item.record &&
            item.record.forEach((v) => {
              videoList.push(v);
            });
        });
        console.log("videoList", videoList);

        videoList.forEach((v) => {
          if (row.id == v.id) {
            this.currentVideoUrl = v.url;
          }
        });
        this.openVideoPlay = true;
      });

      // this.openVideoPlay = true;
      // this.currentVideoUrl = row.url;
    },
    getLessonDetail() {
      getLessonOnlineDetail(this.lessonId).then((res) => {
        res.data.release = res.data.release == "1" ? true : false;
        res.data.bannerImages = res.data.bannerImages
          .map((v) => {
            return v.path;
          })
          .join(",");
        this.form = res.data;
        this.videos = this.form.videos;
        console.log("this.form", this.form);
      });
    },
    coverInput(e) {
      console.log("e", e);
      if (e.length == 0) {
        this.form.coverImage = null;
      } else {
        this.form.coverImage = e[0];
      }
    },
    bannerInput(data) {
      console.log("data", data);

      if (data && data.length > 0) {
        this.form.bannerImages = data.map((item) => {
          return {
            path: item.url,
          };
        });
        console.log("this.form.bannerImages", this.form.bannerImages);
      } else {
        this.form.bannerImages = [];
      }
    },
    detailInput(data) {
      if (data && data.length > 0) {
        // this.form.detailImages = data.map((item) => {
        //   return {
        //     path: item.url,
        //   };
        // });
        this.form.detailImages = data;
      } else {
        this.form.detailImages = [];
      }
    },
    ImageUpload() {},
    onCancel() {
      this.$router.go(-1);
    },
    onSubmit() {
      let p1 = new Promise((resolve, reject) => {
        this.$refs["form"].validate((valid, obj) => {
          if (valid) {
            resolve();
          } else {
            reject(obj);
          }
        });
      });
      let p2 = new Promise((resolve, reject) => {
        this.$refs["videosForm"].validate((valid, obj) => {
          if (valid) {
            resolve();
          } else {
            reject(obj);
          }
        });
      });
      Promise.all([p1, p2])
        .then((res) => {
          let data = {
            ...this.form,
            release: this.form.release ? "1" : "0",
            subCount: this.form.videos.length,
            bannerImages: this.form.bannerImages.split(",").map((v) => {
              return {
                path: v,
              };
            }),
          };
          updateLessonOnline(data).then((res) => {
            this.$router.go(-1);
          });
        })
        .catch((obj) => {
          let keys = Object.keys(obj);
          this.$message.error(obj[keys[0]][0].message);
          return false;
        });
    },
  },
};
</script>
<style lang="scss">
.edit-course-container {
}
</style>
